import { lazy, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { unregister } from 'utils/serviceWorker'

import addPolyfills from 'polyfills'

const App = lazy(() => import(/* webpackChunkName: "application" */ 'App'))

function runApp() {
  addPolyfills().then(render)
}

function render() {
  const container = document.getElementById('root')
  const root = createRoot(container) //
  root.render(
    <Suspense fallback={<div />}>
      <App />
    </Suspense>,
  )
}

runApp()

unregister()
