export default function addPolyfills() {
  return Promise.all([
    importReactApp(),
    importSmoothScroll(),
    importResizeObserver(),
    importIntersectionObserver(),
    importIntl(),
  ])
}

async function importReactApp() {
  const promises = []
  promises.push(import(/* webpackChunkName: "react-app-polyfill-stable" */ 'react-app-polyfill/stable'))
  return Promise.all(promises)
}

async function importSmoothScroll() {
  if (!('scrollBehavior' in document.documentElement.style && window.__forceSmoothScrollPolyfill__ !== true)) {
    const smoothscroll = await import(/* webpackChunkName: "pollyfill-smooth-scroll" */ 'smoothscroll-polyfill')
    smoothscroll.polyfill()
  }
}

async function importResizeObserver() {
  if (!('ResizeObserver' in window)) {
    const ResizeObserver = await import(
      /* webpackChunkName: "pollyfill-resize-observer-polyfill" */ 'resize-observer-polyfill/'
    )
    window.ResizeObserver = ResizeObserver.default
  }
}

async function importIntersectionObserver() {
  if (
    !(
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in window.IntersectionObserverEntry.prototype
    )
  ) {
    await import(/* webpackChunkName: "pollyfill-intersection-observer" */ 'intersection-observer')
  }
}

async function importIntl() {
  const promises = []
  if (!('Intl' in window)) {
    promises.push(import(/* webpackChunkName: "pollyfill-intl" */ 'intl'))
    promises.push(import(/* webpackChunkName: "pollyfill-intl-EN" */ 'intl/locale-data/jsonp/en.js'))
    promises.push(import(/* webpackChunkName: "pollyfill-intl-FR" */ 'intl/locale-data/jsonp/fr.js'))
  }
  if ('Intl' in window && !Intl.PluralRules) {
    promises.push(import(/* webpackChunkName: "pollyfill-intl-pluralrules" */ 'intl-pluralrules'))
  }

  if ('Intl' in window && !Intl.RelativeTimeFormat) {
    // Load the polyfill 1st BEFORE loading data
    await import(
      /* webpackChunkName: "@formatjs/intl-relativetimeformat/polyfill" */ '@formatjs/intl-relativetimeformat/polyfill'
    )

    promises.push(
      import(
        /* webpackChunkName: "@formatjs/intl-relativetimeformat/dist/locale-data/fr" */ '@formatjs/intl-relativetimeformat/locale-data/fr'
      ),
    )
    promises.push(
      import(
        /* webpackChunkName: "@formatjs/intl-relativetimeformat/dist/locale-data/en" */ '@formatjs/intl-relativetimeformat/locale-data/en'
      ),
    )
  }
  return Promise.all(promises)
}
